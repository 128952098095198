






























import { Component, Vue } from 'vue-property-decorator';
import userState from '@/store/user';
import Icon from '@/components/common/elements/Icon.vue';
import GlobalNavigation from '@/components/common/elements/GlobalNavigation.vue';
import { sideMenuConfig, MenuObject } from '@/constants/components/sideMenu';

@Component({
  components: { Icon, GlobalNavigation }
})
export default class SideMenu extends Vue {
  private isActive = true;
  private isMenuLabel = true;
  private menuStatus = '';
  private icon = 'mdi-menu-open';
  private dropDownList: Array<MenuObject> = [];

  mounted(): void {
    this.dropDownList = this.modifyMenu();
  }

  private modifyMenu() {
    let result = sideMenuConfig;
    if (!userState.getIsAdmin) {
      result = result.filter(menu => menu.isAdmin === undefined || !menu.isAdmin);
    }
    return result;
  }
}
