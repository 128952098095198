


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Logo extends Vue {
  // ロゴ横幅(画像の場合有効)
  @Prop({ default: '' })
  private width?: string;
  // ロゴ高さ(画像の場合有効)
  @Prop({ default: '' })
  private height?: string;
  // ロゴテキスト(値があったら画像の代わりテキストが表示)
  @Prop({ default: '' })
  private text?: string;
  // ロゴテキストのサイズ
  @Prop({ default: '' })
  private textLevel?: string;
}
