



















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Icon from '@/components/common/elements/Icon.vue';

@Component({
  components: { Icon }
})
export default class HeaderUtilMenu extends Vue {
  /**
   * チャットアイコン押下時処理(未定)
   * @module getChat
   * @param {Object} data
   * @return {void} - コンソールに出力
   */
  private getChat(data: boolean): void {
    console.log('chat-');
    console.log(data);
  }

  /**
   * helpアイコン押下時処理(未定)
   * @module getHelp
   * @param {Object} data
   * @return {void} - コンソールに出力
   */
  private getHelp(data: boolean): void {
    console.log('help-');
    console.log(data);
  }
}
