

















import { Component, Vue } from 'vue-property-decorator';
import Logo from '@/components/common/elements/Logo.vue';
import HeaderSearch from '@/components/common/modules/header/HeaderSearch.vue';
import HeaderUtilMenu from '@/components/common/modules/header/HeaderUtilMenu.vue';
import HeaderUserInfo from '@/components/common/modules/header/HeaderUserInfo.vue';
import { systemInformation } from '@/constants/systemInformation';

@Component({
  components: {
    Logo,
    HeaderSearch,
    HeaderUtilMenu,
    HeaderUserInfo
  }
})
export default class Header extends Vue {}
