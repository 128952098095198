export const sideMenuConfig = [
  {
    title: '新規文書登録',
    icon: 'mdi-plus',
    isAdmin: false,
    isButton: true,
    route: '/doc_register',
    name: 'Doc_register'
  },
  {
    title: '文書検索・一覧',
    icon: 'mdi-clipboard-search-outline',
    isAdmin: false,
    isButton: false,
    route: '/doc_list',
    name: 'Doc_list'
  },
  {
    title: '文書CSV出力',
    icon: 'mdi-export',
    isAdmin: false,
    isButton: false,
    route: '/doc_csv_output',
    name: 'Doc_csv_output'
  },
  {
    title: 'カテゴリ検索・一覧',
    icon: 'mdi-toy-brick-search-outline',
    isAdmin: false,
    isButton: false,
    route: '/doc_category_list',
    name: 'Doc_category_list'
  },
  {
    title: 'カテゴリ登録',
    icon: 'mdi-toy-brick-plus-outline',
    isAdmin: false,
    isButton: false,
    route: '/doc_category_register',
    name: 'Doc_category_register'
  },
  {
    title: '社員マスタ登録',
    icon: 'mdi-account-plus-outline',
    isAdmin: true,
    isButton: false,
    route: '/setting/employee/register',
    name: 'SettingEmployeeRegister'
  },
  {
    title: '社員マスタ検索',
    icon: 'mdi-account-search-outline',
    isAdmin: true,
    isButton: false,
    route: '/setting/employee/list',
    name: 'SettingEmployeeList'
  }
] as Array<MenuObject>;

export interface MenuObject {
  title: string;
  icon: string;
  isAdmin: boolean;
  isButton: boolean;
  route: string;
  name: string;
}
