








































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { InputResponse } from '@/constants/form/types';

@Component({})
export default class Input extends Vue {
  // 要素タイプ
  @Prop({ default: 'text' })
  private type?: string;
  // 入力要素名
  @Prop({ default: '' })
  private name!: string;
  // 入力要素名
  @Prop({ default: '' })
  private label?: string;
  // 入力要素初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素最大入力数
  @Prop({ default: '' })
  private maxlength?: string;
  // 入力要素がアクティブか否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // アイコンの付与
  @Prop({ default: '' })
  private appendIcon?: string;

  @Prop({ default: '' })
  private prependIcon?: string;
  // アイコンを入力エリア内に付与する
  @Prop({ default: '' })
  private prependInnerIcon?: string;
  // readonly
  @Prop({ default: false })
  private isReadonly?: boolean;
  // バリデーションルール
  @Prop({ default: '' })
  private rule?: string;

  public inputVal = '';
  public errMessage = '';

  // 最大値
  @Prop({ default: null })
  private max?: number;
  // 最小値
  @Prop({ default: null })
  private min?: number;

  mounted() {
    if (this.value) {
      this.inputVal = this.value;
    }
  }

  @Watch('value')
  watchValue() {
    this.inputVal = this.value !== undefined ? this.value : '';
  }

  private get inputValue(): string {
    return this.inputVal;
  }

  private set inputValue(inputVal: string) {
    this.inputVal = inputVal;
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return InputResponse - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(data: any): InputResponse {
    return { name: this.name, value: data };
  }

  createName() {
    if (this.label !== undefined) {
      const keyStr = this.name.match(/\[\d{1,}\]/);
      return keyStr !== null ? this.label + keyStr : this.label;
    }
    return '';
  }
  createMessage(errors: any) {
    const result: string[] = [];
    for (const i in errors) result.push(errors[i].replace(/\[\d{1,}\]/, ''));
    return result;
  }
}
