












import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Input from '@/components/common/elements/Input.vue';

@Component({
  components: { Input }
})
export default class HeaderSearch extends Vue {
  /**
   * 子コンポーネントからEmitされる値をコンソールに出力
   * （値が渡されるか確認用）
   * @module catchInput
   * @param {Object} data - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  private catchInput(data: { [k: string]: string }) {
    console.log(data);
  }
}
