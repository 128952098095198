
























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import baseModel from '@/models/baseModel';

@Component({
  components: {}
})
export default class ChangePasswordModal extends Vue {
  private oldPassword = '';
  private newPassword = '';
  private confirmPassword = '';
  private apiResult = {} as { [k: string]: any };
  private apiSuccess = false;
  private base = new baseModel();

  private async changePassword() {
    try {
      this.apiSuccess = false;
      const params = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        confirm_password: this.confirmPassword
      };
      this.apiResult = (await this.base.changePassword(params)) as { [k: string]: any };
      if (!this.apiResult.error) {
        this.apiSuccess = true;
      }
      // パスワードクリア
      this.initPassword();
    } catch (error) {
      console.log(error);
    }
  }
  @Emit('switchChangePasswordModal')
  private close() {
    this.initPassword();
    this.apiResult = {};
    this.apiSuccess = false;
    return false;
  }

  // アラート関連初期化
  private initPassword(): void {
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }
}
