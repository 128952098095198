






























import { Component, Vue, Prop } from 'vue-property-decorator';
import Icon from '@/components/common/elements/Icon.vue';
import { DropDownListProps } from '@/constants/components/dropDownList';

@Component({
  components: { Icon }
})
export default class DropDownList extends Vue {
  // リストTOPレベル背景色
  @Prop({ default: '' })
  private headerColor?: string;
  // リストコンテンツ背景色
  @Prop({ default: '' })
  private bodyColor?: string;
  // リストTOPレベルアイコン種類 (参考：https://materialdesignicons.com/)
  @Prop({ default: '' })
  private topIcon?: string;
  // 選択可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // リストTOPレベルラベルの表示制御
  @Prop({ default: true })
  private isDisplayLabel?: boolean;
  // リスト開閉制御
  @Prop({ default: '' })
  private value?: any;
  // リスト内項目設定値
  @Prop({ default: [] })
  private list!: Array<DropDownListProps>;
}
