import store, { SESSION_STORAGE_KEY } from '@/store';
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';

export interface UserState {
  /** userName */
  userName: string;
}

/**
 * ログインユーザー関連の状態管理クラス
 * このクラスの状態はリロードしても状態が保持される
 */
@Module({
  name: 'user',
  namespaced: true,
  dynamic: true,
  store,
  // ↓リロードしても状態を保持する場合に、この1行で追加制御が必要
  preserveState: sessionStorage.getItem(SESSION_STORAGE_KEY) !== null
})
export class User extends VuexModule implements UserState {
  userName = '';
  userEmail = '';
  userDepartmentId = '';
  userDepartmentName = '';
  userEmployeeId = '';
  userEmployeeName = '';
  isAdmin = false;
  matterBranchKey = '';
  estimateBranchKey = '';
  cutomerBranchKey = '';

  /**
   * userNameを設定する
   *
   * @param userName HexabaseのuserName
   */
  @Mutation
  setUserName(userName: string) {
    this.userName = userName;
  }

  /**
   * userEmailを設定する
   *
   * @param userEmail HexabaseのuserEmail
   */
  @Mutation
  setUserEmail(userEmail: string) {
    this.userEmail = userEmail;
  }

  /**
   * userDepartmentIdを設定する
   *
   * @param userDepartmentId 社員マスタのdepartment_id
   */
  @Mutation
  setUserDepartmentId(userDepartmentId: string) {
    this.userDepartmentId = userDepartmentId;
  }

  /**
   * userDepartmentNameを設定する
   *
   * @param userDepartmentName 社員マスタのdepartment名
   */
  @Mutation
  setUserDepartmentName(userDepartmentName: string) {
    this.userDepartmentName = userDepartmentName;
  }

  /**
   * userEmployeeIdを設定する
   *
   * @param userEmployeeId 社員マスタのitem_id
   */
  @Mutation
  setUserEmployeeId(userEmployeeId: string) {
    this.userEmployeeId = userEmployeeId;
  }

  /**
   * userEmployeeNameを設定する
   *
   * @param userEmployeeName 社員マスタの社員名
   */
  @Mutation
  setUserEmployeeName(userEmployeeName: string) {
    this.userEmployeeName = userEmployeeName;
  }

  /**
   * isAdminを設定する
   *
   * @param isAdmin flg
   */
  @Mutation
  setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
  }

  /**
   * matterBranchKeyを設定する
   *
   * @param matterBranchKey 社員マスタの社員名
   */
  @Mutation
  setMatterBranchKey(matterBranchKey: string) {
    this.matterBranchKey = matterBranchKey;
  }

  /**
   * estimateBranchKeyを設定する
   *
   * @param estimateBranchKey 社員マスタの社員名
   */
  @Mutation
  setEstimateBranchKey(estimateBranchKey: string) {
    this.estimateBranchKey = estimateBranchKey;
  }

  /**
   * cutomerBranchKeyを設定する
   *
   * @param cutomerBranchKey 社員マスタの社員名
   */
  @Mutation
  setCutomerBranchKey(cutomerBranchKey: string) {
    this.cutomerBranchKey = cutomerBranchKey;
  }

  /**
   * UserStateをリセットする
   */
  @Mutation
  clearUserState() {
    this.userName = '';
    this.userEmail = '';
    this.userDepartmentId = '';
    this.userEmployeeId = '';
    this.userDepartmentName = '';
    this.userEmployeeName = '';
    this.isAdmin = false;
    this.matterBranchKey = '';
    this.estimateBranchKey = '';
    this.cutomerBranchKey = '';
  }

  /**
   * userNameを取得する
   *
   * @returns userName
   */
  public get getUserName(): string {
    return this.userName;
  }

  /**
   * userEmailを取得する
   *
   * @returns userName
   */
  public get getUserEmail(): string {
    return this.userEmail;
  }

  /**
   * userDepartmentIdを取得する
   *
   * @returns userDepartmentId
   */
  public get getUserDepartmentId(): string {
    return this.userDepartmentId;
  }

  /**
   * userEmployeeIdを取得する
   *
   * @returns userEmployeeId
   */
  public get getUserEmployeeId(): string {
    return this.userEmployeeId;
  }

  /**
   * userDepartmentNameを取得する
   *
   * @returns userDepartmentName
   */
  public get getUserDepartmentName(): string {
    return this.userDepartmentName;
  }

  /**
   * userEmployeeNameを取得する
   *
   * @returns userEmployeeName
   */
  public get getUserEmployeeName(): string {
    return this.userEmployeeName;
  }

  /**
   * isAdminを取得する
   *
   * @returns isAdmin
   */
  public get getIsAdmin(): boolean {
    return this.isAdmin;
  }

  /**
   * matterBranchKeyを取得する
   *
   * @returns matterBranchKey
   */
  public get getMatterBranchKey(): string {
    return this.matterBranchKey;
  }

  /**
   * estimateBranchKeyを取得する
   *
   * @returns estimateBranchKey
   */
  public get getEstimateBranchKey(): string {
    return this.estimateBranchKey;
  }

  /**
   * cutomerBranchKeyを取得する
   *
   * @returns cutomerBranchKey
   */
  public get getCutomerBranchKey(): string {
    return this.cutomerBranchKey;
  }
}

export default getModule(User);
