






































import { Component, Vue } from 'vue-property-decorator';
import authState from '@/store/auth';
import userState from '@/store/user';
import HexabaseApi from '@/services/hexabase/hexabaseApi';
import Icon from '@/components/common/elements/Icon.vue';
import SingleText from '@/components/common/elements/SingleText.vue';
import DropDownList from '@/components/common/elements/DropDownList.vue';
import ChangePasswordModal from '@/components/common/modules/ChangePasswordModal.vue';
import { userDropDownMenu } from '@/constants/components/header';

@Component({
  components: { Icon, SingleText, DropDownList, ChangePasswordModal }
})
export default class HeaderUserInfo extends Vue {
  private hexabaseApi = new HexabaseApi();

  /** ドロップダウンメニューの構成情報 */
  dropDownList = userDropDownMenu;
  /** パスワード再設定モーダルの表示状態（true: 表示中） */
  changePasswordModal = false;

  get userDepartment() {
    return userState.userDepartmentName;
  }
  get userName() {
    return userState.userEmployeeName;
  }

  /**
   * ユーザーメニュー押下時処理
   * @param item - メニュ―情報
   * @returns void
   */
  clickMenu(item: { [k: string]: string }): void {
    if (item.slug === 'logout') {
      this.logout();
    }
    if (item.slug === 'changePassword') {
      // パスワード変更モーダルを表示
      this.switchChangePasswordModal();
    }
  }

  /**
   * ログアウト時処理
   * @returns void
   */
  private async logout(): Promise<void> {
    await this.hexabaseApi.logout(authState.token);
    this.$store.commit('hexabase/clearHexabaseState');
    this.$store.commit('auth/clearToken');
    this.$store.commit('user/clearUserState');
    this.$store.commit('search/clearsearchCondition');
    this.$router.push({ name: 'Signin' });
  }

  switchChangePasswordModal(): void {
    this.changePasswordModal = !this.changePasswordModal;
  }
}
